<script setup>
import axios from "axios";
import Lottie from "@/components/widgets/lottie.vue";
import sendAnimationData from "@/components/widgets/loadingsuccessfail.json";
import { ref }  from "vue";
import { useRoute } from 'vue-router';
import 'animate.css'

const route = useRoute();

const sendAnimation = ref({
    animationData: sendAnimationData,
    initialSegment: [1,240]
});

const changeValue = ref(false)
const offerStatus = ref(null)
const successMessage = ref({
    'title' : 'Teklif Onaylanamadı!',
    'message' : '',
    'errCode' : ''
})

function change(status, position){
    sendAnimation.value.initialSegment = position == undefined ? (status?[240,400]:[655,822]) : position
    setTimeout(() => { changeValue.value = true }, 100); 
}

if(route.query.t == undefined){
        offerStatus.value = false
        change(false, [821,822])
} else {
    let tokenData = new FormData();
    
    tokenData.append('token', route.query.t)
    axios.post(process.env.VUE_APP_B2B_API_URL+'auth/confirmOfferDoc', tokenData, {
    headers: {"jwt" : route.query.t}
    }).then((res) => {
        successMessage.value.title = res.data.title
        successMessage.value.message = res.data.message
        if(res.data.status){
            offerStatus.value = true   
        } else {
            offerStatus.value = false
        }
        change(res.data.status)
    }).catch((err) => {
        if(err.response.data.title != undefined) {
            successMessage.value.title  = err.response.data.title
        }
        successMessage.value.message = err.response.data.message
        if(err.response.data.code != undefined) {
            successMessage.value.message += " (" + err.response.data.code + ")"
        }
        offerStatus.value = false
        change(false)
    });

}
</script>

<template>
    <div class="auth-page-wrapper pt-5">

        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>
            <div class="shape">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120"> 
                    <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                </svg>
            </div>
            <Particles id="tsparticles" :options="{
                particles: {
                    number: {
                        value: 90,
                        density: {
                            enable: true,
                            value_area: 800,
                        },
                    },
                    color: { value: '#ffffff' },
                    shape: {
                        type: 'circle',
                        stroke: {
                            width: 0,
                            color: '#000000',
                        },
                        polygon: { nb_sides: 5 },
                        image: {
                            src: 'img/github.svg',
                            width: 100,
                            height: 100,
                        },
                    },
                    opacity: {
                        value: 0.8,
                        random: true,
                        anim: {
                            enable: true,
                            speed: 1,
                            opacity_min: 0,
                            sync: false,
                        },
                    },
                    size: {
                        value: 4,
                        random: true,
                        anim: {
                        enable: false,
                        speed: 4,
                        size_min: 0.2,
                        sync: false,
                        },
                    },
                    line_linked: {
                        enable: false,
                        distance: 150,
                        color: '#ffffff',
                        opacity: 0.4,
                        width: 1,
                    },
                    move: {
                        enable: true,
                        speed: 2,
                        direction: 'none',
                        random: false,
                        straight: false,
                        out_mode: 'out',
                        attract: {
                        enable: false,
                        rotateX: 600,
                        rotateY: 1200,
                        },
                    },
                },
                interactivity: {
                    detect_on: 'canvas',
                    events: {
                        onhover: { enable: true, mode: 'bubble' },
                        onclick: { enable: true, mode: 'repulse' },
                        resize: true,
                    },
                    modes: {
                        grab: {
                            distance: 400,
                            line_linked: { opacity: 1 },
                        },
                        bubble: {
                            distance: 400,
                            size: 4,
                            duration: 2,
                            opacity: 0.8,
                            speed: 3,
                        },
                        repulse: { distance: 200 },
                        push: { particles_nb: 4 },
                        remove: { particles_nb: 2 },
                    },
                },
                retina_detect: true,
                config_demo: {
                    hide_card: false,
                    background_color: '#b61924',
                    background_image: '',
                    background_position: '50% 50%',
                    background_repeat: 'no-repeat',
                    background_size: 'cover',
                },
            }" />
        </div>

        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <router-link to="/" class="d-inline-block auth-logo">
                                    <img src="@/assets/images/logo-light.png" alt="" height="20" />
                                </router-link>
                            </div>
                            <p class="mt-3 fs-15 fw-semibold"> Teklif Onay </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">
                            <div class="card-body p-4 text-center">
                                <div class="avatar-lg mx-auto mt-2">
                                    <div class=" avatar-title bg-light text-success display-3 rounded-circle">
                                        <lottie
                                            colors="primary:#67b173,secondary:#3d78e3"
                                            :options="sendAnimation"
                                            :height="120"
                                            :width="120"
                                            :changeEndStop="changeValue"
                                        />
                                    </div>
                                </div>
                                <div class="mt-4 pt-2">
                                    <div v-if="route.query.t != undefined">
                                        <Transition mode="out-in" enter-active-class="animate__flipInX" leave-active-class="animate__flipOutX" class="animate__animated">
                                                <h4 v-if="offerStatus == null">Teklifiniz Onaylanıyor!</h4>
                                                <h4 v-else>{{ successMessage.title }}</h4>
                                        </Transition>
                                        <Transition mode="out-in" enter-active-class="animate__flipInX" leave-active-class="animate__flipOutX" class="animate__animated">
                                                <p v-if="offerStatus == null" class="text-muted mx-4"> Teklifiniz onaylanıyor, lütfen bekleyiniz. </p>
                                                <p v-else class="text-muted mx-4"> {{ successMessage.message }} {{successMessage.errCode}} </p>
                                        </Transition>
                                        <Transition mode="out-in" enter-active-class="animate__flipInX animate__delay-1s" class="animate__animated">   
                                            <div class="mt-4" v-if="[true, false].indexOf(offerStatus) != -1">
                                                    <a href="https://www.elektromarketim.com" class="btn btn-success w-100">Alışverişe geri dön</a>
                                            </div>
                                        </Transition>
                                    </div>
                                    <div v-else>
                                        <h4>Geçersiz Bağlantı</h4>
                                        <p class="text-muted mx-4"> Geçersiz bağlantı! Lütfen bağlantıyı kontrol ediniz. </p>
                                        <div class="mt-4">
                                            <a href="https://www.elektromarketim.com" class="btn btn-warning w-100">Alışverişe geri dön</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0 text-muted">
                                &copy; {{ new Date().getFullYear() }} Elektromarketim.com   
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>